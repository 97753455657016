/* initialize */
$text-color: #767876;
$text-color-1: white;
$accent-color: rgb(64,157,141); //#409d8d
$background-color: #fcfcfc;
$background-color-1: lighten($accent-color, 20%);
$navbar-background-color: white;

$line-height: 1.5rem;

$min-width: 10rem;
$breakpoint: 40rem;
$max-width: 60rem;

//fonts
@font-face {
  font-family: 'Lexend';
  src: url("/assets/fonts/Lexend-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-variation-settings: "wght" 300;
}

@font-face {
  font-family: 'Roboto';
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$title-font: "Lexend";
$body-font: "Roboto";

/* base */
h1, h2, h3, h4, h5, h6 {
  color: $accent-color;
  font-weight: normal;
  font-family: $title-font;
  margin-bottom: 0px;
  padding: 0px;
}

@media (min-width: $breakpoint) {
  html {
    font-size: 1.1rem;
  }
}

body {
  color: $text-color;
  background: $background-color;
  font-family: "Roboto";
  line-height: $line-height;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: $accent-color;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: calc(0.6 * $max-width);
  margin: 1rem auto;
  display: block;
}

/* layout */
header {
  background: $navbar-background-color;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  font-family: $title-font;

  nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }

  .site-header{
    justify-content: space-between;
    align-items: center;
  }

  .site-logo{
    height: 2rem;
    margin: 1rem 0rem;
  }

  .site-logo-title-container {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  
  .site-title{
    display: block;
    font-size: 2rem;
    text-align: center;
    margin: 0 0.5rem;
  }

  .nav-item {
    color: $text-color;
    font-size: 1rem;
    text-align: center;
    padding: 0.5rem 0.5rem;
    border-radius: 4px;

  }
  .nav-item:hover {
    text-decoration: underline;
  }

  .nav-item-selected {
    color: $accent-color;
  }
}

footer {
  margin-top: auto;
}

.footer-div {
  background: $background-color-1;
  color: $text-color-1;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.main-content {
  padding-top: 0.5rem;
}

// home
.calo-branches-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  a {
    text-decoration: none;
  }
  
  .calo-branch:hover {
    box-shadow: 0 0 .5rem $accent-color;
    text-shadow: 0 0 .5rem $text-color-1;

    img {
      filter: invert(100%) drop-shadow(0 0 0.5rem $text-color-1);
    }
  }
}

.calo-branch {
  margin: 0.25rem;
  padding: 1rem;
  border-radius: 8px;
  color: $text-color-1;
  background-color: $background-color-1;

  img {
    width: clamp($min-width/4, 100%, $max-width/4);
    filter: invert(100%);
    object-fit: contain;
  } 
}

// instagram
@media (max-width: 345px) { // hardcoded 345px because it horizontal scroll occurs for 344px or smaller
  .instagram-container {
    display: none;
  }
}

// officers
.officer-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.officer-card {
  display: flex;
  flex-direction: column;
  width: 15rem;
  font-size: .8rem;

  $radius: 3rem;
  img {
    margin-bottom: 0;
    height: 2 * $radius;
    width: 2 * $radius;
    border-radius: $radius;
    border: 2px solid $accent-color;
    object-fit: cover;
  }
}

// misc
.wrapper {
  width: clamp($min-width, 90%, $max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (min-width: $breakpoint) {
  .flex-row {
    display: flex;
    flex-direction: row;
  }
}

.secondary-color {
  color: $text-color-1;

  h1, h2, h3, h4, h5, h6 {
    color: $text-color-1;
  }
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}

.center-text {
  text-align: center;
}

.center-flex {
  align-content: center;
  justify-content: center;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.warning {
  background: red;
}

.images-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  img {
    margin: 1rem;
    width: 15rem;
    object-fit: cover;
  }
}
